<template>
  <div :class="$style.settings">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="7.5rem"
    >
      <div :class="$style.blockTitle">Seo</div>
      <el-form-item label="SEO-Faqs">
        <SeoFaqsBlock
          :seoFAQs="form.seoFaqs"
          :project="$options.PROJECTS.ADDSELLERS"
          @getSeo="getSeoFaqs"
        />
      </el-form-item>
      <div :class="$style.blockTitle">Описание</div>
      <el-form-item label="Заголовок" prop="descriptionSection.title">
        <el-input v-model="form.descriptionSection.title" />
      </el-form-item>
      <el-form-item label="Описание" prop="descriptionSection.description">
        <TextEditor
          :value.sync="form.descriptionSection.description"
          :is-image="false"
          :is-media="false"
          :height="400"
        />
      </el-form-item>
      <div :class="$style.blockTitle">Контент страницы</div>
      <el-button
        style="margin-bottom: 1rem"
        type="primary"
        icon="el-icon-plus"
        @click="addContentSectionItem"
      >
        Добавить
      </el-button>
      <template v-if="form.contentSection.length">
        <div v-for="(content, i) in form.contentSection" :key="i">
          <div :class="$style.contentSection">
            <el-form-item
              :class="$style.titleInput"
              label="Заголовок"
              :prop="'contentSection.' + i + '.title'"
              :rules="rules.contentSection.title"
            >
              <el-input v-model="content.title" />
            </el-form-item>
            <el-button
              icon="el-icon-delete-solid"
              @click="removeContentSectionItem(i)"
            />
          </div>
          <el-form-item
            label="Контент"
            :prop="'contentSection.' + i + '.description'"
            :rules="rules.contentSection.description"
          >
            <TextEditor
              :value.sync="content.description"
              :is-image="false"
              :is-media="false"
              :height="400"
            />
          </el-form-item>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import { PROJECTS } from '@/constants/projects/index.js'

export default {
  PROJECTS,
  components: {
    SeoFaqsBlock,
    TextEditor,
  },
  data() {
    return {
      form: {
        seoFaqs: [],
        descriptionSection: {
          title: '',
          description: '',
        },
        contentSection: [],
      },
      rules: {
        descriptionSection: {
          title: {
            required: true,
            message: 'Заполните поле',
            trigger: 'change',
          },
          description: {
            required: true,
            message: 'Заполните поле',
            trigger: 'change',
          },
        },
        contentSection: {
          type: 'array',
          title: {
            required: true,
            message: 'Заполните поле',
            trigger: 'change',
          },
          description: {
            required: true,
            message: 'Заполните поле',
            trigger: 'change',
          },
        },
      },
    }
  },
  created() {
    this.getContent('bonus-program-description')
  },
  methods: {
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    addContentSectionItem() {
      this.form.contentSection.push({
        title: '',
        description: '',
      })
    },
    removeContentSectionItem(index) {
      this.form.contentSection.splice(index, 1)
    },
    async getContent(slug) {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'sellers')
      loading.close()
      if (error) return
      value.content.seoFaqs =
        value.content?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []

      this.form.seoFaqs = value.content.seoFaqs
      this.form.descriptionSection.title =
        value.content.descriptionSection?.title
      this.form.descriptionSection.description =
        value.content.descriptionSection?.description
      this.form.contentSection = value.content.contentSection || []
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))
          const data = {
            ...this.form,
            seoFaqs,
          }
          const result = await delivery.ContentServiceCore.PagesActions.update(
            'bonus-program-description',
            'sellers',
            data,
          )
          if (result.error) {
            alert('Ошибка обновлена страницы: ' + result.error)
          } else {
            alert('Старница успешно обновлена')
          }
          loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.settings {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .blockTitle {
    @include blockTitle;
  }

  .contentSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;

    &:not(:first-of-type) {
      margin: 5rem 0 1rem 0;
    }
    .titleInput {
      flex: 1 1 100%;
    }
  }
}
</style>
