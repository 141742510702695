<template>
  <div>
    <el-select
      :class="$style.search"
      v-model="options"
      multiple
      filterable
      default-first-option
      remote
      :remote-method="findSeoFaq"
      v-el-select-loadmore="loadMoreSeoFaqs"
      placeholder="Выберите SEO-FAQ"
      @change="$emit('getSeo', options)"
      @remove-tag="deleteSeo($event)"
    >
      <el-option
        v-for="item in seoFAQsOptions"
        :key="item.id"
        :label="item.question"
        :value="item.id"
        @click="getSeoFaqs()"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { debounce } from '@/helpers/debounce.js'
import { PROJECTS } from '@/constants/projects/index.js'

export default {
  mixins: [notifications],
  props: {
    seoFAQs: {
      type: Array,
      default: () => []
    },
    project: {
      type: String,
      default: PROJECTS.ADDWINE
    }
  },
  data() {
    return {
      options: [],
      optionsDefault: [],
      seoFAQsOptions: [],
      page: 1,
      limit: 20,
      count: 0,
      isCallSetDefaultOptions: true,
      isWithNotification: true
    }
  },

  async created() {
    await this.getSeoFaqs()
    await this.setDefaultOptions()
  },

  watch: {
    async seoFAQs() {
      this.isCallSetDefaultOptions && (await this.setDefaultOptions())
    }
  },

  methods: {
    deleteSeo(seoFAQId) {
      const isOptionsDefaultHasSeoFAQId = this.optionsDefault?.some(
        (option) => option === seoFAQId
      )
      if (isOptionsDefaultHasSeoFAQId) {
        const seoFaqToDelete = this.seoFAQsOptions?.find(
          (option) => option.id === seoFAQId
        )

        const isConfirm = confirm(
          `Вы точно хотите удалить ${
            seoFaqToDelete ? seoFaqToDelete.question : 'SEO-FAQ'
          }`
        )

        isConfirm
          ? this.$emit('deleteSeo', seoFAQId)
          : this.options.push(seoFAQId)
      }
    },

    async setDefaultOptions() {
      this.options = this.seoFAQs

      if (this.seoFAQs?.length) {
        if (!this.optionsDefault.length) {
          this.optionsDefault = this.seoFAQs
        }

        const seoIds = this.options.join(',')

        const { value, error } =
          await delivery.ContentServiceCore.SeoFaqsActions.getByIds(seoIds)

        if (error) {
          this.showNotification(
            'К сожалению, SEO-FAQ, которые были добавлены на этой странице - удалены или прикреплены к другой странице, удалите некорректные из них',
            'error'
          )
          this.isCallSetDefaultOptions = false
          return
        }

        let hasDeletedSeoFaq = false
        value?.forEach((item) => {
          hasDeletedSeoFaq = this.seoFAQs?.every((seoFaq) => seoFaq !== item.id)
        })

        if (hasDeletedSeoFaq) {
          this.isWithNotification &&
            this.showNotification(
              'К сожалению, SEO-тексты, которые были добавлены на этой странице - удалены или прикреплены к другой странице, удалите некорректные из них',
              'error'
            )

          this.isWithNotification = false
        }

        value?.forEach((x) => {
          if (!this.seoFAQsOptions.find((y) => y.id === x.id)) {
            this.seoFAQsOptions.unshift(x)
          }
        })
      }
    },
    loadMoreSeoFaqs() {
      if (this.limit * this.page < this.count) {
        this.page++
        const query = {
          page: this.page,
          limit: this.limit
        }
        this.addSeoFaqs(query)
      }
    },
    findSeoFaq(name) {
      debounce(this.searchSeoFaqs(name), 500)
    },
    async searchSeoFaqs(name) {
      if (name === '') {
        this.page = 1
        this.getSeoFaqs()
      }
      if (name.split('').length > 2) {
        const loading = this.$loading({
          lock: true
        })

        const params = {
          limit: 20,
          search: name,
          isLinked: false
        }

        const { value, error } =
          await delivery.ContentServiceCore.SeoFaqsActions.getList(params)

        loading.close()

        if (error) return

        this.seoFAQsOptions = value.data
        this.count = value.meta.count
      }
    },
    async addSeoFaqs(query) {
      const loading = this.$loading({
        lock: true
      })

      const params = {
        page: query?.page || 1,
        limit: query?.limit || 20,
        search: query?.search || null,
        isLinked: false
      }

      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList(params)

      loading.close()

      if (error) return
      value.data.forEach((x) => {
        if (!this.seoFAQsOptions.find((y) => y.id === x.id))
          this.seoFAQsOptions.push(x)
      })
      this.count = value.meta.count
    },
    async getSeoFaqs(query) {
      const loading = this.$loading({
        lock: true
      })

      const params = {
        page: query?.page || 1,
        limit: query?.limit || 20,
        search: query?.search || null,
        project: this.project,
        isLinked: false
      }

      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList(params)

      loading.close()

      if (error) return

      this.seoFAQsOptions = value.data
      this.count = value.meta.count
    }
  }
}
</script>

<style lang="scss" module>
.search {
  width: 100%;
}
</style>
